.cardWidth {
    width: 30rem;
  }
  
.wrapXml {
  overflow-x: auto;
}

.spinSize {
  font-size:46px;
}

.tableScrollable {
  overflow-y: auto;
  height: 250px;
}

button.fixedLink {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  vertical-align: unset !important;
  color: #007bff;
  background-color: #FFFF;
  border-color: #FFFF;
  cursor: pointer;

  -moz-user-select: text;
}

.btnClose {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}