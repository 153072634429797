.cardWidth {
    width: 30rem;
}

.towerIcon {
    width: 40px;
}

.serialData {
    width: 100%;
}